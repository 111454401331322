import detailDefault from "../../detailDefault";

export default class detailacm_stock_vehicles extends detailDefault {
  load(sAction, data) {
    setTimeout(() => {
      const market = sAction.dataGet(data.prefix + "/fields/market/value");
      const visible_to_all_cz = sAction.dataGet(data.prefix + "/fields/visible_to_all_cz/def/visible");

      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/visible_to_all_cz/def/visible", market === "CZ");
      sAction.dsProcess();

    }, 500);
  }

  update(sAction, data) {
    if (data.field === "market") {
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/visible_to_all_cz/def/visible", data.value === "CZ");
      sAction.dsProcess();
    }
  }
}
